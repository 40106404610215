import React from 'react';
import { Heading } from '../../components';
import { richText } from '../../util/richText';
import { FormattedMessage } from 'react-intl';

import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 20;

const urlify = (text) => {
  // Regular expression to detect URLs
  const urlPattern = /(https?:\/\/[^\s]+)/ig;

  // Split the text by URLs and map over the parts
  return text.split(urlPattern).map((part, index) => {
    // If the part matches the URL pattern, wrap it in an <a> tag
    if (part.match(urlPattern)) {
      return <a key={index} href={part} target="_blank" rel="noopener noreferrer">{part}</a>;
    }
    // Otherwise, return the text part
    return part;
  });
};

const SectionUrlMaybe = props => {
  const { text, i18nkey, heading, showAsIngress = false } = props;
  const content = richText(text, {
    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
    longWordClass: css.longWord,
    breakChars: '/',
  });

  return text ? (
    <section className={css.sectionText}>
      {heading ? (
        <Heading as="h2" rootClassName={css.sectionHeading}>
          <FormattedMessage id={`field.${i18nkey}`} />
        </Heading>
      ) : null}
      <div>
        {urlify(text)}
      </div>
    </section>
  ) : null;
};

export default SectionUrlMaybe;
